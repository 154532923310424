<template>
  <v-menu v-model="isOpen" :close-on-content-click="false" min-width="290" offset-y>
    <template v-slot:activator="{ on }">
      <v-icon v-if="showAsIcon" v-on="on">mdi-calendar</v-icon>
      <v-text-field
        v-else
        v-on="on"
        :value="value"
        :clearable="clearable"
        :label="label"
        :error-messages="errorMessages"
        :hide-details="hideDetails"
        :hint="hint"
        prepend-icon="mdi-calendar"
        autocomplete="off"
        ref="textField"
        @blur="onBlur"
        @keyup.enter="onEnterClick"
        @click:prepend="onClickPrepend"
        @click:clear="onClear"
      />
    </template>
    <v-date-picker
      :value="value"
      :locale="locale"
      first-day-of-week="1"
      no-title
      scrollable
      @input="onInput"
    />
  </v-menu>
</template>

<script>
import { format, isValid } from 'date-fns';
import i18n from '../../i18n/i18n-config';

export default {
  name: 'BaseDatepickerInput',

  props: {
    value: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: i18n.t('date'),
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },
    hint: {
      type: String,
      default: '',
    },

    hideDetails: {
      type: Boolean,
      default: false,
    },

    showAsIcon: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      timerID: null,
    };
  },

  computed: {
    locale() {
      return this.$store.state.settings.locale;
    },
  },

  methods: {
    onBlur(event) {
      if (event.target.value === this.value || this.value === null) {
        return;
      }
      const date = new Date(event.target.value);
      if (isValid(date)) {
        this.onInput(format(date, 'yyyy-MM-dd'));
      } else {
        this.onInput('');
      }
    },

    onClear() {
      this.onInput('');
      this.$refs.textField.blur();
    },

    onEnterClick(event) {
      event.target.blur();
    },

    onInput(val) {
      this.$emit('input', val);
      this.isOpen = false;
    },

    onClickPrepend() {
      this.isOpen = true;
    },
  },
};
</script>
