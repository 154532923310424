<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="900"
    scrollable
    persistent
    @keydown.esc="goToOrdersPage"
  >
    <OrderForm
      :order="editedOrder"
      :errors="orderValidationErrors"
      :disabled="$store.getters.loading[`put:api/orders/${editedOrder.id}`]"
      @clear:errors="clearOrderValidationErrors"
      @cancel="goToOrdersPage"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import OrderForm from '@/components/OrderForm';
import { mapActions, mapState } from 'vuex';
import store from '@/store';

export default {
  name: 'EditOrder',

  components: { OrderForm },

  computed: mapState('orders', ['editedOrder', 'orderValidationErrors', 'orderFilterParams']),

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('orders/editOrder', +to.params.id)
      .then(() => {
        next();
      })
      .catch(() => {
        next({ name: 'orders', query: store.state.orders.orderFilterParams });
      });
  },

  methods: {
    ...mapActions('orders', ['updateOrder', 'clearOrderValidationErrors']),

    onSave(order) {
      this.updateOrder(order).then(() => {
        this.goToOrdersPage();
      });
    },

    goToOrdersPage() {
      this.$router.push({ name: 'orders', query: this.orderFilterParams }).catch(() => {});
    },
  },
};
</script>

<style scoped></style>
