<template>
  <v-form @submit.prevent="$emit('save', order)" autocomplete="off">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="order.internal_no"
              :error-messages="errors['internal_no']"
              :label="$t('internal_no')"
              @input="formMixin_clearErrors('internal_no')"
            />
          </v-col>

          <v-col cols="12" sm="3">
            <v-checkbox
              v-model="order.is_important"
              :error-messages="errors['is_important']"
              :label="$t('is_important')"
              @change="formMixin_clearErrors('is_important')"
            />
          </v-col>

          <v-col cols="12" sm="3">
            <BaseDatepickerInput
              v-model="order.date"
              :error-messages="errors['date']"
              :label="formMixin_getRequiredFieldLabel($t('date'))"
              @input="
                formMixin_clearErrors('date');
                setDeadlineAtDate($event);
              "
            />
          </v-col>

          <v-col cols="12" sm="3">
            <BaseDatepickerInput
              v-model="order.deadline_at"
              :error-messages="errors['deadline_at']"
              :label="$t('deadline_at')"
              @input="formMixin_clearErrors('deadline_at')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="order.buyer_name"
                  :error-messages="errors['buyer_name']"
                  :label="formMixin_getRequiredFieldLabel($t('buyer_name'))"
                  @input="formMixin_clearErrors('buyer_name')"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="order.installation_address"
                  :error-messages="errors['installation_address']"
                  :label="$t('installation_address')"
                  @input="formMixin_clearErrors('installation_address')"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="order.buyer_phone"
                  :error-messages="errors['buyer_phone']"
                  :label="$t('buyer_phone')"
                  @input="formMixin_clearErrors('buyer_phone')"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="order.buyer_email"
                  :error-messages="errors['buyer_email']"
                  :label="$t('buyer_email')"
                  @input="formMixin_clearErrors('buyer_email')"
                />
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="order.retail_price_coef"
                  :error-messages="errors['retail_price_coef']"
                  :label="$t('retail_price_coef')"
                  rows="1"
                  auto-grow
                  @change="formMixin_clearErrors('retail_price_coef')"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="6">
            <v-textarea
              v-model="order.comments"
              :error-messages="errors['comments']"
              :label="$t('comments')"
              rows="1"
              auto-grow
              @change="formMixin_clearErrors('comments')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="order.is_installation_ordered"
              :error-messages="errors['is_installation_ordered']"
              :label="$t('is_installation_ordered')"
              @change="formMixin_clearErrors('is_installation_ordered')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-if="order.is_installation_ordered"
              v-model="order.installation_price"
              :error-messages="errors['installation_price']"
              :label="$t('installation_price')"
              type="number"
              min="0"
              step="0.01"
              @change="formMixin_clearErrors('installation_price')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="order.handles_price"
              :error-messages="errors['handles_price']"
              :label="$t('handles_price')"
              type="number"
              min="0"
              step="0.01"
              @change="formMixin_clearErrors('handles_price')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-textarea
              v-model="order.comment_for_handles"
              :error-messages="errors['comment_for_handles']"
              :label="$t('handles')"
              rows="1"
              auto-grow
              @input="formMixin_clearErrors('comment_for_handles')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              v-model="order.collar_cut_angle"
              :error-messages="errors['collar_cut_angle']"
              :label="$t('collar_cut_angle')"
              :items="collarCutAngles"
              @input="formMixin_clearErrors('collar_cut_angle')"
            />
          </v-col>

          <v-col cols="12" sm="6" class="d-flex align-center subtitle-1">
            {{ $t('calculated_price') }}: {{ this.calculatedPrice }}
            <!--            <v-text-field-->
            <!--              v-model="order.calculated_price"-->
            <!--              :error-messages="errors['calculated_price']"-->
            <!--              :label="$t('calculated_price')"-->
            <!--              type="number"-->
            <!--              min="0"-->
            <!--              step="0.01"-->
            <!--              @change="formMixin_clearErrors('calculated_price')"-->
            <!--            />-->
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="order.final_overridden_price"
              :error-messages="errors['final_overridden_price']"
              :label="$t('final_overridden_price')"
              type="number"
              min="0"
              step="0.01"
              @change="formMixin_clearErrors('final_overridden_price')"
            />
          </v-col>

          <v-col v-if="order.id && order.documents.length" cols="12">
            <DocumentTable
              :items="order.documents"
              :loading="$store.getters.loading['get:api/orders']"
              @view:document="viewOrderDocument"
              @delete="removeOrderDocument"
            />
          </v-col>
          <v-col cols="12">
            <div class="v-label theme--light my-5">{{ $t('file_dropzone_message') }}</div>

            <BaseFileDropZone @drop="onFilesSelect" multiple>
              <div class="drop-zone">
                <div v-for="(file, index) in order.files" :key="index" class="file-icon-width">
                  <div class="file-icon">
                    <v-icon x-large>mdi-file</v-icon>
                    <v-icon small class="close-icon" @click.stop="removeFile(index)">
                      mdi-close
                    </v-icon>
                  </div>
                  <BaseTruncatedField :text="file.name" :truncatedLength="8" />
                </div>
                <v-icon x-large class="mx-3">mdi-plus-circle</v-icon>
              </div>
            </BaseFileDropZone>
            <div
              v-if="documentErrors.length"
              class="v-messages error--text mb-2"
              :class="$isExplorer() ? 'mt-0' : 'mt-4 '"
            >
              <div v-for="(error, index) in documentErrors" :key="index">
                {{ error[0] }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!order.id" class="text-subtitle-2 ml-3"> * {{ $t('must_be_filled') }}</span>

        <v-spacer />

        <v-btn text color="primary" @click="$emit('cancel')">{{ $t('cancel') }}</v-btn>

        <v-btn :disabled="disabled" :loading="disabled" color="primary" text type="submit">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <router-view
      @preview:close="
        $router.push({ name: 'editOrder', params: { id: $route.params.id } }).catch(() => {})
      "
    />
  </v-form>
</template>

<script>
import formMixin from '@/mixins/form-mixin';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import BaseFileDropZone from '@/components/base/BaseFileDropZone';
import BaseTruncatedField from '@/components/base/BaseTruncatedField';
import DocumentTable from '@/components/DocumentTable';
import eventBus, { OPEN_SNACKBAR, openConfirmDialog } from '@/util/event-bus';
import documentService from '@/api/document-service';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { format, addDays } from 'date-fns';
import { sanitizeDateString } from '@/util/strings';

export default {
  name: 'OrderForm',
  components: { DocumentTable, BaseTruncatedField, BaseFileDropZone, BaseDatepickerInput },
  mixins: [formMixin],

  props: {
    order: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),

    collarCutAngles() {
      return [
        {
          value: null,
          text: this.$t('ask_client'),
        },
        {
          value: 90,
          text: '90',
        },
        {
          value: 45,
          text: '45',
        },
      ];
    },

    calculatedPrice() {
      let calculatedPrice = 0;
      for (let i = 0; i < this.order.parts.length; i++) {
        calculatedPrice += this.order.parts[i].calculated_price;
      }
      return calculatedPrice.toFixed(2);
    },

    formTitle() {
      return this.$t(this.order.id ? 'edit_order' : 'new_order');
    },

    documentErrors() {
      const docErrors = [];
      Object.keys(this.errors).forEach((error) => {
        if (error.includes('file')) {
          docErrors.push(this.errors[error]);
        }
      });
      return docErrors;
    },
  },

  methods: {
    ...mapMutations('orders', ['REMOVE_ORDER_DOCUMENT']),
    ...mapActions('orders', ['clearOrderValidationErrors']),

    setDeadlineAtDate(startDate) {
      let value = '';
      if (startDate) {
        value = format(
          addDays(
            new Date(sanitizeDateString(startDate)),
            this.currentUser.person.client[0].manufacturing_term_in_days,
          ),
          'yyyy-MM-dd',
        );
      }
      this.$set(this.order, 'deadline_at', value);
      this.clearOrderValidationErrors('deadline_at');
    },

    onFilesSelect(files) {
      const fileArray = [];
      for (let i = 0; i < files.length; i++) {
        fileArray.push(files[i]);
      }
      // this.$set(this.order, 'files', fileArray);
      this.order.files.push(...fileArray);
      this.clearOrderValidationErrors('files.0');
    },

    viewOrderDocument(document) {
      this.$router
        .push({ name: 'orderDocument/view', params: { documentId: document.id } })
        .catch(() => {});
    },

    removeOrderDocument(document) {
      openConfirmDialog({
        title: this.$t('confirm_document_delete'),
      }).then((confirmed) => {
        if (!confirmed) {
          return;
        }
        documentService.delete(document).then(() => {
          this.REMOVE_ORDER_DOCUMENT(document);
          eventBus.$emit(OPEN_SNACKBAR, this.$t('document_deleted'));
        });
      });
    },

    removeFile(index) {
      this.order.files.splice(index, 1);
    },
  },
};
</script>

<style scoped></style>
